import React, { lazy, Suspense } from 'react';

const IntroTextNacoWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextNacoWebStrankyCChLazy' */ './IntroTextNacoWebStrankyCCh'));

function IntroTextNacoWebStrankyCChLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextNacoWebStrankyCCh ...</div>}>
                <IntroTextNacoWebStrankyCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextNacoWebStrankyCChLazy;